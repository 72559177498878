§<script setup>
import { ref, onMounted, useSSRContext } from "vue"
import { useToast } from "primevue/usetoast"
import axios from "axios"
import { FilterMatchMode } from "primevue/api"
import { EnumStringBody } from "@babel/types"
import { useConfirm } from "primevue/useconfirm"
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false);
const toast = useToast();
const confirm = useConfirm();
// filters
const filter_renewals = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
//////////////////
// Data Sources //
//////////////////
const renewals = ref()
const expired_orders = ref()
const expired_exceptions = ref()
const exception = ref()
const get_base_data = () => {
  axios
    .get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-all-renewals")
    .then((response) => {
      renewals.value = response.data
    })
    .catch((error) => {
      console.error("There was an error!", error.message);
    });
  axios
    .get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-expired-orders")
    .then((response) => {
      expired_orders.value = response.data
      console.log(expired_orders.value)
    })
    .catch((error) => {
      console.error("There was an error!", error.message);
    });
  axios
    .get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-expired-exceptions")
    .then((response) => {
      expired_exceptions.value = response.data
    })
    .catch((error) => {
      console.error("There was an error!", error.message);
    });
};
onMounted(() => {
  get_base_data();
})

const add_exception = (id) => {
  axios
    .post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/add-order-exception", {id: id})
    .then((response) => {
      expired_exceptions.value = response.data
    })
    .catch((error) => {
      console.error("There was an error!", error.message);
    });
}

const remove_exception = (id) => {
  confirm.require({
      message: 'Bist du sicher, dass du diese Ausnahme entfernen möchtest?',
      header: 'Ausnahme: ' + id + ' entfernen?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Ja, Ausnahme entfernen',
      acceptClass: 'p-button-danger',
      rejectLabel: 'Nein',
      accept: () => {
      axios
        .post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/remove-order-exception", {id: id})
        .then((response) => {
          expired_exceptions.value = response.data
        })
        .catch((error) => {
          console.error("There was an error!", error.message);
        });
      }
  })
}

const format_currency = (value) => {
  return Number(value).toLocaleString("de-CH", {
    style: "currency",
    currency: "CHF",
    minimumFractionDigits: 2,
  });
}
const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}
</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
  <Toast />
  <div class="col-12 mb-2">
    <Toolbar>
      <template #start>
        <Dropdown v-model="exception" :options="expired_orders" optionValue="document_nr" optionLabel="document_nr" placeholder="Auftrag auswählen" class="mr-2" />
        <Button :disabled="exception ? false : true" label="Ausnahme hinzufügen" icon="pi pi-plus" @click="add_exception(exception)" />
      </template>
      <template #end>
        <span class="p-input-icon-left mr-2" style="width: 300px">
          <i class="pi pi-search" />
          <InputText v-model="filter_renewals['global'].value" placeholder="Suche" />
        </span>
      </template>
    </Toolbar>
  </div>
  <div class="p-3">
    <span class="font-bold mr-2">Ausnahme Aufträge</span>
    <Avatar icon="pi pi-info" class="bg-primary text-white mr-3" v-tooltip.top="'Abgelaufene Aufträge die trotzdem erneuert werden können - Bitte 15 Minuten warten vor die Aufträge unten angezeigt werden'" shape="circle" />
    <div class="flex flex-row flex-wrap mt-2">
      <template v-for="expired_exception in expired_exceptions" :key="expired_exception">
        <div class="mr-2 bg-primary text-white mb-1 flex align-items-center justify-content-center border-round p-2 px-3 font-bold shadow-4"><span class="mr-2">{{ expired_exception }}</span><i @click="remove_exception(expired_exception)" class="pi pi-times-circle text-white cursor-pointer"></i></div>
      </template>
    </div>
  </div>
  <DataTable v-model:filters="filter_renewals" :value="renewals" :rows="20" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" :paginator="true" :autoLayout="true">
    <Column field="is_valid_from" header="Datum" sortable>
        <template #body="slotProps">
            {{ format_swiss_date(slotProps.data.is_valid_from) }}
        </template>
    </Column>
    <Column field="customer.name_1" header="Kunde" sortable>
      <template #body="slotProps">
        <a :href="'https://office.bexio.com/index.php/kontakt/show/id/' + slotProps.data.contact_id" target="_blank" class="font-bold">{{ slotProps.data.customer.name_2 }} {{ slotProps.data.customer.name_1 }}</a>
      </template>
    </Column>
    <Column field="document_nr" header="Auftrag" sortable hidden> </Column>
    <Column>
      <template #body="slotProps">
        <a :href="'https://office.bexio.com/index.php/kb_order/show/id/' + slotProps.data.id" target="_blank"><Tag :value="slotProps.data.document_nr + ' - ' + slotProps.data.title + ' - ' + format_currency(slotProps.data.total)" class="mr-2 shadow-2" icon="pi pi-file" severity="info" /></a>
      </template>
    </Column>
  </DataTable>
</template>