<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { EnumStringBody } from '@babel/types'
import { useConfirm } from "primevue/useconfirm"

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_deliveries = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_delivery_details = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const deliveries = ref([])
const delivery_notes = ref([])
const delivery_notes_filtered = ref([])

const get_deliveries = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-deliveries")
        .then(response => {
            deliveries.value = response.data
            console.log("deliveries", deliveries.value)
            get_delivery_notes()
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}
const get_delivery_notes = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-delivery-notes")
        .then(response => {
            delivery_notes.value = response.data
            // filter delivery notes to only show the ones that are not already assigned
            console.log("delivery_notes length before", delivery_notes.value.length)
            delivery_notes_filtered.value = delivery_notes.value.filter(item => !deliveries.value.some(delivery => delivery.delivery_note === item.id))
            console.log("delivery_notes length after", delivery_notes_filtered.value.length)
            console.log("delivery_notes", delivery_notes.value)
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}
onMounted(() => {
    get_deliveries()
})
const delete_delivery = (workflow_id) => {
    confirm.require({
        message: 'Bist du sicher, dass du diese Auslieferung löschen möchtest?',
        header: 'Auslieferung löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Auslieferung löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            console.log("delete delivery", workflow_id)
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/delete-delivery", {"workflow_id": workflow_id})
            .then(response => {
                console.log("response", response.data)
                get_deliveries()
                toast.add({ severity: 'success', summary: 'Auslieferung gelöscht!', detail: 'Auslieferung wurde gelöscht', life: 3000})
            })
        }
    })
}
const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}
const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const deliveryDetailsDialog = ref(false)
const delivery_details = ref([])
const delivery_details_Workflow_id = ref(null)
const open_delivery_details = (details) => {
    delivery_details.value = []
    for (const order of details.orders) {
        for (const product of order.lines) {
            delivery_details.value.push({
                sku: product.sku,
                order: order.orderId,
                quantity: product.quantity,
                name: product.name,
                kgEquiv: product.kgEquiv,
                processingCost: product.processingCost
            })
        }
    }
    delivery_details_Workflow_id.value = details.workflow_id
    deliveryDetailsDialog.value = true
    console.log("delivery_details",delivery_details.value)
}

const dt_delivery = ref()
const exportCSV = () => {
    dt_delivery.value.exportCSV()
}

const delivery_changes = ref([])
const save_delivery_note = (workflow_id, delivery_note) => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/save-delivery-note", {"workflow_id": workflow_id, "delivery_note": delivery_note})
    .then(response => {
        console.log("response", response.data)
        delivery_changes.value = delivery_changes.value.filter(item => item !== workflow_id)
        get_deliveries()
        toast.add({ severity: 'success', summary: 'Lieferschein gespeichert!', detail: 'Lieferschein wurde gespeichert', life: 3000})
    })
    .catch(error => {
        console.error("There was an error!", error.message);
    })
}

const save_delivery = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/save-delivery", {"delivery_details": delivery_details.value, "workflow_id": delivery_details_Workflow_id.value})
    .then(response => {
        get_deliveries()
        toast.add({ severity: 'success', summary: 'Auslieferung gespeichert!', detail: 'Auslieferung wurde gespeichert', life: 3000})
    })
    .catch(error => {
        console.error("There was an error!", error.message);
    })
}

const delivery_note_import_dialog = ref(false)
const selected_delivery_note = ref(null)

const import_delivery_note = () => {
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/import-delivery-note", {"delivery_note": selected_delivery_note.value})
    .then(response => {
        console.log("response", response.data)
        get_deliveries()
        toast.add({ severity: 'success', summary: 'Lieferschein importiert!', detail: 'Lieferschein wurde importiert', life: 3000})
        delivery_note_import_dialog.value = false
    })
    .catch(error => {
        console.error("There was an error!", error.message);
    })
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <div class="col-12 mb-2">
        <Toolbar>
            <template #start>
                <span class="p-input-icon-left mr-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_deliveries['global'].value" placeholder="Suche" style="width: 300px" />
                </span>
                <Button label="Lieferschein importieren" icon="pi pi-download" v-tooltip.top="'Lieferschein importieren'" class="mr-2" @click="delivery_note_import_dialog = true, selected_delivery_note = null" />
            </template>
        </Toolbar>
    </div>
    <DataTable v-model:filters="filter_deliveries" :value="deliveries" :rows="20" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" :autoLayout="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column field="date" header="Datum">
            <template #body="slotProps">
                {{ format_swiss_date(slotProps.data.date) }}
            </template>
        </Column>
        <Column header="Umfang">
            <template #body="slotProps">
                <Button @click="open_delivery_details(slotProps.data)" :label="String(slotProps.data.orders.length) + ' Aufträge'" icon="pi pi-eye" size="small" class="w-auto" />
            </template>
        </Column>
        <Column field="userEmail" header="Kunde">
            <template #body="slotProps">
                <span>{{ slotProps.data.userEmail }}</span>
            </template>
        </Column>
        <Column field="delivery_note" header="Lieferschein">
            <template #body="slotProps">
                <Dropdown filter v-model="slotProps.data.delivery_note" :options="delivery_notes" optionLabel="description" optionValue="id" placeholder="Lieferschein auswählen" @change="delivery_changes.push(slotProps.data.workflow_id)" />
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button v-if="delivery_changes.includes(slotProps.data.workflow_id)" @click="save_delivery_note(slotProps.data.workflow_id, slotProps.data.delivery_note)" icon="pi pi-save" class="p-button-success" />
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button @click="delete_delivery(slotProps.data.workflow_id)" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" />
            </template>
        </Column>
    </DataTable>

    <Dialog v-model:visible="deliveryDetailsDialog" :style="{width: '1200px'}" header="Auslieferung Details" :modal="true" class="p-fluid">
        <Card class="shadow-4 z-5 mt-2" style="position: sticky; top: 0">
            <template #content>
                <div class="flex align-items-center justify-content-center">
                    <Button @click="save_delivery()" icon="pi pi-save" label="Speichern" class="w-auto bg-green-700 border-green-600 text-white mr-2" v-tooltip.top="'Änderungen speichern'" />
                    <Button icon="pi pi-times" label="Abbrechen" class="w-auto bg-orange-700 border-orange-600 text-white mr-2" v-tooltip.top="'Abbrechen'" />
                </div>
            </template>
        </Card>
        <div rounded class="overflow-hidden shadow-3 border-200">
            <div class="col-12 formgrid grid">
                <div class="field col-12 mt-3">
                    <DataTable ref="dt_delivery" :value="delivery_details" v-model:filters="filter_delivery_details" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[50,100,200]"  :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                        <Column field="sku" header="SKU" />
                        <Column field="quantity" header="Anzahl" style="width: 20%">
                            <template #body="slotProps">
                                <InputNumber class="neuraxis_inputnumber" showButtons v-model="slotProps.data.quantity" />
                            </template>
                        </Column>
                        <Column field="name" header="Produkt" />
                        <Column field="kgEquiv" header="Kg-Eq" />
                        <Column field="processingCost" header="Verarbeitungskosten" />
                        <Column field="order" header="Auftrag" />
                    </DataTable>
                </div>
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="delivery_note_import_dialog" :style="{width: '1200px'}" header="Lieferschein importieren" :modal="true" class="p-fluid">
        <div class="grid">
            <div class="col-12 mt-3">
                <span class="font-bold mr-2">{{ String(delivery_notes_filtered.length) }}</span><span>Bexio Lieferscheine sind nicht an eine Auslieferungen im Snappy zugewiesen.</span>
            </div>
            <div class="col-12 mt-3">
                <span class="p-float-label">
                    <Dropdown id="delivery_note" filter v-model="selected_delivery_note" :options="delivery_notes_filtered" optionLabel="description" optionValue="id" />
                    <label for="delivery_note">Lieferschein auswählen</label>
                </span>
            </div>
            <div class="col-12 mt-3">
                <Button :disabled="selected_delivery_note ? false : true" label="Importieren" icon="pi pi-download" @click="import_delivery_note" class="mr-2 w-auto p-button-success" />
                <Button label="Abbrechen" icon="pi pi-times" @click="delivery_note_import_dialog = false" class="w-auto p-button-danger" />
            </div>
        </div>
    </Dialog>
</template>