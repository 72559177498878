<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()

// filters
const filter_unpaid = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const unpaid = ref()
const get_base_data = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-unpaid-invoices")
        .then(response => {
            unpaid.value = response.data
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}
onMounted(() => {
    get_base_data()
})
const delete_unpaid = (id) => {
    confirm.require({
        message: 'Bist du sicher, dass du die Rechnung & Aufträge löschen möchtest?',
        header: 'Rechnung: ' + id + ' löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Rechnung & Aufträge löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            toast.add({ severity: 'warn', summary: 'Rechnung & Auftrag gelöscht!', detail: 'Rechnung ' + id + ' wurde gelöscht wie auch die Aufträge', life: 3000})
        }
    })
}
const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}
const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}
</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <div class="col-12 mb-2">
        <Toolbar>
            <template #start>
            </template>
            <template #end>
                <span class="p-input-icon-left mr-2" style="width: 300px">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_unpaid['global'].value" placeholder="Suche" />
                </span>
                <Button type="button" icon="pi pi-cog" aria-controls="overlay_menu" class="p-button-rounded bg-blue-500" />
            </template>
        </Toolbar>
    </div>
    <DataTable v-model:filters="filter_unpaid" :value="unpaid" :rows="20" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" :autoLayout="true">
        <Column field="is_valid_from" header="Datum" sortable>
            <template #body="slotProps">
                {{format_swiss_date(slotProps.data.is_valid_from)}}
            </template>
        </Column>
        <Column field="name" header="Kunde" sortable>
            <template #body="slotProps">
                <a :href="'https://office.bexio.com/index.php/kontakt/show/id/' + slotProps.data.contact_id" target="_blank" class="font-bold">{{slotProps.data.name}}</a>
            </template>
        </Column>
        <Column field="invoice_nr" header="Rechnung" sortable hidden>
        </Column>
        <Column field="food_order_nr" header="Lebensmittel" hidden>
        </Column>
        <Column field="wheat_order_nr" header="Weizen" hidden>
        </Column>
        <Column>
            <template #body="slotProps">
                <a :href="'https://office.bexio.com/index.php/kb_invoice/show/id/' + slotProps.data.invoice_id" target="_blank"><Tag :value="slotProps.data.invoice_nr + ' - ' + slotProps.data.invoice_title + ' - ' + format_currency(slotProps.data.invoice_total)" class="mr-2" icon="pi pi-file" severity="danger" /></a>
                <a :href="'https://office.bexio.com/index.php/kb_order/show/id/' + slotProps.data.food_order_id" target="_blank"><Tag v-if="slotProps.data.food_order_nr" :value="slotProps.data.food_order_nr + ' - ' + slotProps.data.food_order_title + ' - ' + format_currency(slotProps.data.food_order_total)" class="mr-2" icon="pi pi-file" severity="warning" /></a>
                <a :href="'https://office.bexio.com/index.php/kb_order/show/id/' + slotProps.data.wheat_order_id" target="_blank"><Tag v-if="slotProps.data.wheat_order_nr" :value="slotProps.data.wheat_order_nr + ' - ' + slotProps.data.wheat_order_title + ' - ' + format_currency(slotProps.data.wheat_order_total)" class="mr-2" icon="pi pi-file" severity="warning" /></a>
                <a :href="slotProps.data.link" target="_blank"><Tag value="Rechnung Link" class="mr-2" icon="pi pi-external-link" /></a>
                <Tag v-if="slotProps.data.rates > 1" value="Raten" style="background-color: #dee2e6; color: #000" class="mr-2" />
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button @click="delete_unpaid(slotProps.data.invoice_nr)" icon="pi pi-trash" class="p-button-rounded p-button-text p-button-danger" />
            </template>
        </Column>
    </DataTable>
</template>