<script setup>
import { ref, onMounted, useSSRContext } from "vue"
import { useToast } from "primevue/usetoast"
import axios from "axios"
import { FilterMatchMode } from "primevue/api"
import { EnumStringBody } from "@babel/types"
import { useConfirm } from "primevue/useconfirm"
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false);
const toast = useToast();
const confirm = useConfirm();
// filters
const filter_renewals = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
//////////////////
// Data Sources //
//////////////////
const expired_orders = ref()
const get_base_data = () => {
  axios
    .get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-expired-orders")
    .then((response) => {
      expired_orders.value = response.data
      console.log(expired_orders.value)
    })
    .catch((error) => {
      console.error("There was an error!", error.message);
    });
};
onMounted(() => {
  get_base_data();
})

const format_currency = (value) => {
  return Number(value).toLocaleString("de-CH", {
    style: "currency",
    currency: "CHF",
    minimumFractionDigits: 2,
  });
}
const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}
</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
  <Toast />
  <div class="col-12 mb-2">
    <Toolbar>
      <template #start>
      </template>
      <template #end>
        <span class="p-input-icon-left mr-2" style="width: 300px">
          <i class="pi pi-search" />
          <InputText v-model="filter_renewals['global'].value" placeholder="Suche" />
        </span>
      </template>
    </Toolbar>
  </div>
  <DataTable v-model:filters="filter_renewals" :value="expired_orders" :rows="20" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" :paginator="true" :autoLayout="true">
    <Column field="is_valid_from" header="Datum" sortable>
        <template #body="slotProps">
            {{ format_swiss_date(slotProps.data.is_valid_from) }}
        </template>
    </Column>
    <Column field="document_nr" header="Auftrag" sortable hidden> </Column>
    <Column>
      <template #body="slotProps">
        <a :href="'https://office.bexio.com/index.php/kb_order/show/id/' + slotProps.data.id" target="_blank"><Tag :value="slotProps.data.document_nr + ' - ' + slotProps.data.title + ' - ' + format_currency(slotProps.data.total)" class="mr-2 shadow-2" icon="pi pi-file" severity="info" /></a>
      </template>
    </Column>
  </DataTable>
</template>