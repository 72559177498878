<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_kgeq = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })
//////////////////
// Data Sources //
//////////////////
const kgeq = ref()
const pre_save_kgeq = ref()
const new_kgeq = ref()
const changes = ref(0)
const save_text = ref("Speichern")
const newKqEqDialog = ref(false)
const get_base_data = () => {
    // Get Data
    // set all values to edit object
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-kilo-equivalence")
        .then(response => {
            kgeq.value = response.data["lebensmittel"]
            pre_save_kgeq.value = JSON.parse(JSON.stringify(response.data["lebensmittel"]))
            console.log("kgeq: ", kgeq.value)
        }).catch(error => {
            console.error("There was an error!", error.message);
        })
}
onMounted(() => {
    get_base_data()
})
const newKgEq = () => {
    new_kgeq.value = {
        "name": '',
        "price": 0,
        "metric": 0
    }
    newKqEqDialog.value = true
}
const check_metric_exists = (metric, index) => {
    for (let i = 0; i < kgeq.value.length; i++) {
        if (i != index && kgeq.value[i].metric == metric) {
            return true
        }
    }
    return false
}
function onCellEditComplete(event) {
    let index = kgeq.value.findIndex((obj) => obj.metric == event.data.metric);
    if (event.field == "metric" && check_metric_exists(event.newValue, index)) {
        toast.add({ severity: 'error', summary: 'Kg-Äquivalenz: ' + event.newValue + ' existiert!', detail: 'Bitte eine KG-Äquivalenz wählen die noch nicht existiert', life: 3000 })
    }
    else {
        kgeq.value[index][event.field] = event.newValue
        console.log("changes:", kgeq.value[index][event.field])
        if (pre_save_kgeq.value[index][event.field] != event.newValue) {
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({ severity: 'success', summary: 'Angepasst', detail: event.newValue, life: 3000 })
            console.log("pre_save_content: ", event)
        }
    }
}
const saveKgEq = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/update-kilo-equivalence", kgeq.value)
        .then(response => {
            kgeq.value = response.data["lebensmittel"]
            newKqEqDialog.value = false
            toast.add({ severity: 'success', summary: 'Gespeichert', detail: "Deine Anpassungen wurden erfolgreich gespeichert", life: 3000 })
            changes.value = 0
            save_text.value = "Speichern"
            loader.value = false
        }).catch(error => {
            console.error("There was an error!", error.message);
        });
}
const reset_data = () => {
    kgeq.value = JSON.parse(JSON.stringify(pre_save_kgeq.value))
    changes.value = 0
    save_text.value = "Speichern"
}
const delete_kgeq = (metric) => {
    confirm.require({
        message: 'Bist du sicher, dass du das Feld: ' + metric + ' löschen möchtest?',
        header: 'Feld: ' + metric + ' löschen?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Ja, Feld löschen',
        acceptClass: 'p-button-danger',
        rejectLabel: 'Nein',
        accept: () => {
            let index = kgeq.value.findIndex((obj) => obj.metric == metric);
            kgeq.value.splice(index, 1)
            changes.value = changes.value + 1
            save_text.value = "Speichern (" + changes.value + ")"
            toast.add({ severity: 'warn', summary: 'Feld gelöscht!', detail: 'Feld ' + metric + ' wurde gelöscht', life: 3000 })
        }
    })
}
const saveNewKgEq = () => {
    if (new_kgeq.value.metric && new_kgeq.value.price && new_kgeq.value.name && !check_metric_exists(new_kgeq.value.metric, -1)) {
        let new_kgeq_json = { metric: '', price: '', name: '' }
        new_kgeq_json['metric'] = new_kgeq.value.metric
        new_kgeq_json['price'] = new_kgeq.value.price
        new_kgeq_json['name'] = new_kgeq.value.name
        kgeq.value.push(new_kgeq_json)
        changes.value = changes.value + 1
        save_text.value = "Speichern (" + changes.value + ")"
        newKqEqDialog.value = false
        toast.add({ severity: 'success', summary: 'Neues Feld', detail: 'Neues Feld wurde erfolgreich hinzugefügt', life: 3000 })
    } else {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Bitte alle Felder ausfüllen oder KG-Äquivalenz "' + new_kgeq.value.metric + '" existiert schon', life: 3000 })
    }
}
const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', { style: 'currency', currency: 'CHF', minimumFractionDigits: 2 });
}
</script>

<style scoped>
.p-inputtext {
    opacity: 1 !important;
}

.p-multiselect-token {
    color: #ffffff !important;
    background-color: #2196F3;
}

.p-datatable-header {
    padding: 0px !important;
}

.non-float-label {
    color: #6c757d;
    font-size: 12px;
    padding-left: 0.5rem;
}
</style>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
    <div class="col-12 mb-2">
        <Toolbar>
            <template #start>
                <Button v-if="changes != 0" v-tooltip.bottom="'Anpassungen zurücksetzen'" @click="reset_data" class="mr-2 p-button-danger" icon="pi pi-refresh" />
                <Button :disabled="changes != 0 ? false : true" @click="saveKgEq()" v-tooltip.bottom="'Anpassungen Speichern'" :label="save_text" class="w-auto mr-4 p-button-success" icon="pi pi-save" />
                <Button @click="newKgEq()" class="mr-5 p-button" v-tooltip.bottom="'Neues Feld hinzufügen'" icon="pi pi-plus" />
            </template>
            <template #end>
                <span class="p-input-icon-left mr-2" style="width: 300px">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_kgeq['global'].value" placeholder="Suche" />
                </span>
                <Button type="button" icon="pi pi-cog" aria-controls="overlay_menu" class="p-button-rounded bg-blue-500" />
            </template>
        </Toolbar>
    </div>
    <DataTable editMode="cell" @cell-edit-complete="onCellEditComplete" v-model:filters="filter_kgeq" :value="kgeq"
        :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true"
        :autoLayout="true">
        <Column field="name" header="Name" sortable style="width: 300px">
            <template #editor="{ data, field }">
                <InputText style="width:100%;" v-model="data[field]" autofocus />
            </template>
            <template #body="{ data, field }">
                <span class="font-bold">{{ data[field] }}</span>
            </template>
        </Column>
        <Column field="price" header="Preis" sortable style="width: 300px">
            <template #editor="{ data, field }">
                <InputNumber style="width:100%;" mode="currency" currency="CHF" locale="de-CH" v-model="data[field]"
                    autofocus showButtons />
            </template>
            <template #body="{ data, field }">
                <span>{{ format_currency(data[field]) }}</span>
            </template>
        </Column>
        <Column field="metric" header="Kg-Äquivalenz" sortable style="width: 300px">
            <template #editor="{ data, field }">
                <InputNumber style="width:100%;" mode="decimal" locale="de-CH" v-model="data[field]" autofocus
                    showButtons :minFractionDigits="2" />
            </template>
            <template #body="{ data, field }">
                <span>{{ data[field] }}</span>
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button @click="delete_kgeq(slotProps.data.metric)" icon="pi pi-trash"
                    class="p-button-rounded p-button-text p-button-danger" />
            </template>
        </Column>
    </DataTable>
    <!-- Dialogs -->
    <Dialog v-model:visible="newKqEqDialog" :style="{width: '650px'}" header="Feld hinzufügen" :modal="true"
        class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-8">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="new_kgeq.name" />
                    <label for="name">Name</label>
                </span>
            </div>
            <div class="field col-12 mt-8">
                <span class="p-float-label">
                    <InputNumber id="price" mode="currency" currency="CHF" locale="de-CH" v-model="new_kgeq.price"
                        showButtons />
                    <label for="price">Preis</label>
                </span>
            </div>
            <div class="field col-12 mt-8">
                <span class="p-float-label">
                    <InputNumber id="metric" mode="decimal" locale="de-CH" v-model="new_kgeq.metric" showButtons
                        :minFractionDigits="2" />
                    <label for="metric">Kg-Äquivalenz</label>
                </span>
            </div>
            <div class="field col-12 mt-8">
                <span class="p-float-label">
                    <Button label="Speichern" @click="saveNewKgEq()" class="w-auto mr-2 mb-1 p-button-success"
                        icon="pi pi-save" />
                    <Button label="Abbrechen" @click="newKqEqDialog = false" class="w-auto mr-2 mb-1 p-button-danger"
                        icon="pi pi-times" />
                </span>
            </div>
        </div>
    </Dialog>
</template>
